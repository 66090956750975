import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuStateService {
  private itemConditionsSubject = new BehaviorSubject<{ [key: string]: boolean }>({});
  itemConditions$ = this.itemConditionsSubject.asObservable();

  setItemCondition(item: string, condition: boolean) {
    const currentConditions = this.itemConditionsSubject.value;
    this.itemConditionsSubject.next({ ...currentConditions, [item]: condition });
  }
}
