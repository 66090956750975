import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '',
        title: '  Try-on',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: '',
        submenu: [
            // { 
            //     path: 'asdas',
            //     title: 'Image capturing', 
            //     iconType: '', 
            //     icon: '',
            //     iconTheme: '',
            //     submenu: [  ] 
            // },
            // { 
            //     path: 'asdas',
            //     title: ' Necklace Selection', 
            //     iconType: '', 
            //     icon: '',
            //     iconTheme: '',
            //     submenu: [  ] 
            // },
            // { 
            //     path: 'sdsd',
            //     title: 'Cloting Generation', 
            //     iconType: '', 
            //     icon: '',
            //     iconTheme: '',
            //     submenu: [  ] 
            // },
            // { 
            //     path: 'asdas',
            //     title: 'Background Enhancement', 
            //     iconType: '', 
            //     icon: '',
            //     iconTheme: '',
            //     submenu: [  ] 
            // }, 
        ]
    },
    
    
    // {
    //     path: '',
    //     title: 'Multi Level Menu',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'appstore',
    //     submenu: [
    //         { 
    //             path: '',
    //             title: 'Level 1', 
    //             iconType: '', 
    //             icon: '',
    //             iconTheme: '',
    //             submenu: [
    //                 {
    //                     path: '',
    //                     title: 'Level 2',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: []
    //                 }    
    //             ] 
    //         }
    //     ]
    // }
]    