<div class="header">
  

     <!-- 
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div> -->
    <div class="nav-wrap">
        <ul class="nav-left">
            <!-- <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li> -->
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>


        <div class="logo logo-dark">
            <a href="">
                <!-- <img *ngIf="!mobileImageURL" src="assets/images/logo/logo.png" alt="Logo">  -->
                <img *ngIf="mobileImageURL" [src]="mobileImageURL" alt="Logo"> 
            </a>
        </div>

        
        <ul class="nav-right">
            <!-- <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li> -->

            <!-- <li>
                <nz-select [(ngModel)]="selectedStore" (ngModelChange)="onStoreChange($event)">
                    <nz-option nzValue="Store1" nzLabel="Store 1"></nz-option>
                    <nz-option nzValue="Store2" nzLabel="Store 2"></nz-option>
                    <nz-option nzValue="Store3" nzLabel="Store 3"></nz-option>
                    <nz-option nzValue="Store4" nzLabel="Store 4"></nz-option>
                  </nz-select>
            </li> -->


            <li>
                <span class="p-h-10 pointer d-flex align-items-center gap-4" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" nzSrc="assets/images/avatars/user.png"></nz-avatar> <span class="d-none d-lg-block"> Ishwor Subedi</span>   <i class="font-size-10" nz-icon nzType="down" theme="outline"></i>   
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzSrc="assets/images/avatars/user.png"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">Ishwor Subedi</p>
                                    <p class="m-b-0 opacity-07">User</p>
                                </div>
                            </div>
                        </li>
                        <!-- <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Edit Profile</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                                    <span class="m-l-10">Account Setting</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="project" theme="outline"></i>
                                    <span class="m-l-10">Projects</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li> -->
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
        </ul>
    </div>
</div>    