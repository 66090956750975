import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private selectedStoreSource = new BehaviorSubject<string>('Store1');
  selectedStore$ = this.selectedStoreSource.asObservable();

  setSelectedStore(store: string) {
    this.selectedStoreSource.next(store);
  }

  private clientConfigSubject = new BehaviorSubject<any>(null);
  clientConfig$ = this.clientConfigSubject.asObservable();

  setClientConfig(config: any) {
    this.clientConfigSubject.next(config);
  }
  
}